<!-- Title Bar -->
<div class="title-bar">
    <div zmid="title" class="title">
        <h1 title="{{ 'GRIDS' | translate }}" tourAnchor="firstGridsAnchor">
            {{ "GRIDS" | translate
            }}<fa-icon
                class="ms-2 tourStartButton"
                icon="question-circle"
                #tooltip="matTooltip"
                matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_GRIDS_BY_CLICKING_HERE' | translate }}"
                matTooltipPosition="below"
                matTooltipClass="below"
                size="xs"
                (click)="tourService.start(); mixpanelService.sendEvent('start grids feature guide')"
            ></fa-icon>
        </h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset" tourAnchor="secondGridsAnchor">
        <ul ngbNav [(activeId)]="activeTabUrl" #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem="grid-list-tab" id="grid-list-tab">
                <a zmid="grids-tab" ngbNavLink class="nav-link" routerLink="{{ urls.grid_list }}" title="{{ 'GRIDS' | translate }}">{{
                    "GRIDS" | translate
                }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem="group-list-tab" id="group-list-tab">
                <a zmid="group-tab" ngbNavLink class="nav-link" routerLink="{{ urls.group_list }}" title="{{ 'GROUPS' | translate }}">{{
                    "GROUPS" | translate
                }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem="grids-visualization-tab" id="grids-visualization-tab">
                <a zmid="group-tab" ngbNavLink class="nav-link" routerLink="{{ urls.grids_visualization }}" title="{{ 'VISUALIZATION' | translate }}">{{
                    "VISUALIZATION" | translate
                }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
