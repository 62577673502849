<spinner-animation *ngIf="loadingDetails"></spinner-animation>
<div
    class="grid-content"
    #gridContent
    [ngStyle]="{
        'height.%': rows || grid?.rows ? 100 : 'auto'
    }"
>
    <div
        class="grid-row"
        *ngFor="let objects of objects$ | async; trackBy: trackByFunction"
        [ngStyle]="{
            'min-height': (rows || grid?.rows) && fitToScreen ? 0 : tileHeight + 'px',
            'flex-basis.%': 100 / gridRows,
            'flex-grow': fitToScreen ? 1 : 0
        }"
    >
        <div
            class="grid-object"
            *ngFor="let object of objects; trackBy: trackByFunction"
            [ngStyle]="{ 'flex-basis.%': 100 / gridCols, 'max-width.%': 100 / gridCols }"
        >
            <div *ngIf="!grid.thumbnails" class="grid-content-container">
                <app-grid-content [grid]="grid" [object]="object" [ngbPopover]="objectContent" container="body"></app-grid-content>
                <ng-template #objectContent>
                    <app-grid-content [grid]="grid" [object]="object" [popover]="true"></app-grid-content>
                </ng-template>
            </div>
            <div *ngIf="grid.thumbnails" class="grid-content-container">
                <app-grid-content [grid]="grid" [object]="object" [overlay]="displayOverlay" [infoBtm]="showInfoBtm"></app-grid-content>
            </div>
        </div>
    </div>
    <div class="grid-no-results" *ngIf="!(objects$ | async).length">
        <strong>{{ "NO_RESULTS" | translate }}</strong>
    </div>
</div>
<!-- Pagination -->
<div class="grid-bottom" #gridBottom [ngClass]="{ 'p-0 border-0': !showBottomBar }" *ngIf="controls">
    <div class="toggle-bottombar-btn" *ngIf="isExpanded" (click)="toggleBottomBar()">
        <fa-icon icon="angle-double-up" size="xs" *ngIf="!showBottomBar"></fa-icon>
        <fa-icon icon="angle-double-down" size="xs" *ngIf="showBottomBar"></fa-icon>
    </div>
    <div class="d-flex flex-wrap grid-gap-3" *ngIf="showBottomBar">
        <div>
            <ngb-pagination [collectionSize]="total$ | async" [(page)]="page" [pageSize]="pageSize" [maxSize]="2"></ngb-pagination>
        </div>
        <!-- Showing -->
        <div class="mt-2">
            <small>{{ "SHOWING" | translate }}: {{ startNum }} - {{ endNum }} of {{ total$ | async }}</small>
        </div>
        <!-- Cycle -->
        <div>
            <div class="form-inline">
                <div class="form-group stay-inline mb-0">
                    <button
                        type="button"
                        class="btn btn-outline-primary me-2"
                        (click)="toggleGridCycle(cyclePaginationInterval)"
                        title="{{ 'AUTO_PAGINATION' | translate }}"
                    >
                        <fa-icon icon="play" size="xs" *ngIf="!cyclePagination"></fa-icon>
                        <fa-icon icon="pause" size="xs" *ngIf="cyclePagination"></fa-icon>
                    </button>
                    <label class="sr-only" for="cycle_pagination_interval">{{ "CYCLE_PAGES_EVERY" | translate }}</label>
                    <input
                        type="number"
                        name="cycle_pagination_interval"
                        class="form-control maxw-60px"
                        [(ngModel)]="cyclePaginationInterval"
                        (ngModelChange)="intervalChange()"
                    />
                    <label class="ms-1">{{ "SECONDS" | translate | lowercase }}</label>
                </div>
            </div>
        </div>
    </div>
</div>
